@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap");
@import url("ionicons.min.css");

body {
  font-size: 16px;
  font-family: "Nunito", sans-serif;
  color: #000;
  overflow-x: hidden;
}

body.rtl {
  direction: rtl;
}

body[dir="rtl"] .header-icon {
  right: 10px;
  left: auto;
}

button:focus,
select:focus {
  outline: none !important;
  box-shadow: none !important;
}

img {
  max-width: 100%;
}

a {
  display: block;
}

a[href] {
  cursor: pointer;
}

.section {
  padding: 100px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.full-screen {
  min-height: 100vh;
}

.custom-select {
  position: relative;
}

.custom-select:before {
  content: "\f3d0";
  font-family: "Ionicons";
  position: absolute;
  right: 10px;
  top: 5px;
  pointer-events: none;
  font-size: 18px;
}

.custom-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-progress-appearance: inherit;
  padding: 4px 25px 6px 8px;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid #ddd;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
}

.title {
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  margin-bottom: 30px;
}

header .navbar-toggler {
  border: 0;
}

/* Header Css */

header .navbar-brand {
  font-size: 20px;
}

header.sticky .navbar {
  background-color: #f4f8fd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

header .navbar .navbar-nav .nav-link {
  padding: 20px 15px;
  cursor: pointer;
}

header .navbar .navbar-nav .nav-link::selection {
  pointer-events: none;
}

.swiper-wrapper {
  align-items: center;
}

.fixed-top {
  z-index: 1000;
  background-color: #f4f8fd;
  padding: 12px 8px;
}

.not-home .navbar,
header.sticky.not-home .navbar {
  background-color: #369fff;
}

.SideBar {
  overflow: auto;
  overflow-y: auto;
  max-width: 320px;
  width: 100%;
  min-height: 100vh;
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  bottom: 0;
  transition: all 0.5s ease;
  -moz-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  background-color: #ffffff !important;
}

.header-icon {
  display: flex;
  align-items: center;
}

.header-icon svg {
  width: 40px;
  color: #7c99b9;
}

.header-icon svg:hover {
  color: #369eff;
}

.logout-icon,
.diamond-icon {
  margin-top: 3px;
}

.logout-icon svg {
  width: 35px;
}

.notification-icon {
  position: relative;
}

.notification-count {
  background-color: #ff0000;
  color: #fff;
  align-items: center;
  font-size: 10px;
  position: absolute;
  top: 0px;
  left: -2px;
  width: 18px;
  border-radius: 100%;
  height: 18px;
  text-align: center;
  line-height: 18px;
}

.empty-notification {
  background-color: #ff5046;
  color: #fff;
  align-items: center;
  font-size: 9px;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 16px;
  border-radius: 100%;
  height: 16px;
  text-align: center;
  line-height: 18px;
}

body[dir="rtl"] .SideBar {
  left: auto;
}

body[dir="rtl"] .SideBar {
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

body[dir="rtl"] .nav__item:not(:last-of-type) {
  margin-right: 0;
  margin-left: 40px;
}

.SideBar.show {
  -moz-transform: translateX(0) !important;
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
}

body[dir="rtl"] .SideBar {
  left: auto;
  right: 0;
}

.sidebar_title {
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex !important;
}

/* .sidebar_title p {
  color: #10214f;
  margin-bottom: 0;
  font-size: 16px;
  word-break: break-all;
  margin-top: 0;
  display: block;
} */
.profile-name {
  color: #10214f;
  font-size: 18px;
  font-weight: 600;
  word-break: break-all;
  margin-bottom: 4px;
  display: block;
}

.profile-id {
  color: #586484;
  font-size: 16px;
  word-break: break-all;
  margin: 0;
  display: block;
  line-height: normal;
}

.profile-button {
  padding: 30px;
  background-color: #fff;
}

.header-wrap .navbar {
  border-bottom: 1px solid #ddd;
}

.header-wrap .navbar .container-fluid > .container-fluid {
  padding: 0;
}

.header-wrap > .navbar > .container-fluid {
  justify-content: flex-end;
}

.header-wrap > .navbar > .container-fluid > .container-fluid {
  justify-content: space-between;
  display: flex;
  flex-wrap: inherit;
  align-items: center;
}

body[dir="rtl"] .header-wrap > .navbar > .container-fluid {
  justify-content: flex-end;
}

.header-wrap .navbar-toggler.bg-transparent {
  display: block;
  margin-right: auto;
}

body[dir="rtl"] .header-wrap .navbar-toggler {
  margin-left: auto;
  margin-right: 0;
}

.navbar-toggler {
  display: none;
}

.sidebar_menu li {
  /* width: 100%; */
  margin: 24px 24px 0;
}

.sidebar_menu li:first-child {
  margin: 0;
  border-bottom: 1px solid #cddef1;
}

.sidebar_menu li button {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: inherit;
  font-size: 16px;
}

.sidebar_menu li button i {
  color: #000;
  font-size: 24px;
  height: 28px;
  line-height: 28px;
}

.sidebar_menu li button h6 {
  display: inline-block;
  margin: 0 8px 0 30px;
  font-size: 18px;
  margin-bottom: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color emoji;
  color: #7c99b9;
}

body[dir="rtl"] .sidebar_menu li button h6 {
  margin: 0 30px 0 8px;
}

.sidebar-right-side {
  width: 100%;
  float: right;
  padding: 70px 40px 16px;
  min-height: 100vh;
}

.side-bar-menu {
  width: inherit;
  float: inherit;
  padding: 70px 10px 16px;
  min-height: 100vh;
  margin-left: 112px;
}

.SideBar .ant-avatar {
  width: 72px;
  height: 72px;
  line-height: 12px;
  margin-right: 20px;
  border-radius: 12px;
}

body[dir="rtl"] .SideBar .ant-avatar {
  margin-right: inherit;
  margin-left: 20px;
}

.SideBar .ant-avatar .ant-avatar-string {
  top: 40%;
}

.b-sidebar-backdrop {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  background-color: #000;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}

.b-sidebar-backdrop.show {
  opacity: 0.6;
  visibility: visible;
}

body[dir="rtl"] .sidebar-right-side {
  float: left;
}

body[dir="rtl"] .side-bar-menu {
  float: inherit;
  margin-left: inherit;
  margin-right: 112px;
}

.SideBar .sidebar_menu {
  background-color: #ffffff;
}

.sidebar-right-side .nav-pills .nav-link.active,
.sidebar-right-side .nav-pills .show > .nav-link {
  background-color: #3ea8ff;
}

.SideBar .nav-pills .nav-link.active {
  background-color: rgb(54 159 255 / 35%);
  border-radius: 10px;
  padding: 13px 16px;
}

.SideBar .nav-pills .nav-link {
  border-radius: 0;
  align-items: center;
}

.SideBar .nav-pills .nav-link svg {
  color: #7c99b9;
}

.SideBar .nav-pills .nav-link.active h6,
.SideBar .nav-pills .nav-link.active svg {
  color: #369fff;
}

.sidebar-right-side .nav-pills .nav-link {
  color: #000;
}

.sidebar-view {
  position: fixed;
  width: 112px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-right: 1px solid #cddef1;
  z-index: 9;
  top: 63px;
}

body[dir="rtl"] .sidebar-view {
  left: inherit;
  right: 0;
  border-left: 1px solid #cddef1;
  border-right: 0;
}

.sidebar-view li .nav-link {
  margin: 24px auto;
  background-color: transparent;
  border: none;
  border-radius: 10px;
  min-height: 50px;
}

.sidebar-view li .nav-link {
  color: #7c99b9;
}

.sidebar-view li .nav-link.active {
  background-color: rgb(54 159 255 / 35%);
  color: #369fff;
}

.sidebar-view li .nav-link.active svg,
.sidebar-view li .nav-link:hover {
  color: #369fff;
}

.ant-tooltip-inner {
  background: #cddef1;
  border-radius: 6px;
  color: #586484;
}

.chat-detail {
  background-color: #f8f4ef;
  border-radius: 15px;
  padding: 15px;
  position: relative;
}

.chat-detail .anticon-close-circle {
  position: absolute;
  font-size: 20px;
  right: 7px;
  top: 7px;
}

body[dir="rtl"] .chat-detail .anticon-close-circle {
  right: auto;
  left: 7px;
}

.home-detail {
  width: 178px;
  background-color: #f4f8fd;
  border-radius: 12px;
  color: #fff;
  margin-right: 16px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

body[dir="rtl"] .home-detail {
  margin-right: inherit;
  margin-left: 16px;
}

.home-detail:hover {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
}

.night-hours-box {
  flex: 0 0 auto;
  width: 20%;
  padding: 0 0.5rem;
}

.night-hour-block {
  max-width: 800px;
  margin: 0 auto;
}

.night-hours {
  background-color: #f1eded;
  border-radius: 15px;
  padding: 11px 15px;
  justify-content: center;
  text-align: center;
  margin: auto;
  font-size: 22px;
}

.night-hour-block .btn {
  min-width: 148px;
  height: 54px;
  border-radius: 10px;
  background-color: #79cbfb;
  border-color: #79cbfb;
  font-size: 20px;
}

.night-hours-box .night-hours.select {
  background-color: #1640aa;
  color: #fff;
}

.night-hours-box .night-hours.selected-box {
  background-color: #79cbfb !important;
}

.night-hours-box .night-hours.deselect {
  background-color: #ff0000 !important;
}

.night-hour-form .ant-spin {
  margin-left: 10px;
  margin-right: -16px;
}

.night-hour-form .ant-spin .anticon svg {
  fill: #fff;
}

.user-img {
  background-color: #0179d7;
  text-align: center;
  border-radius: 12px;
  max-height: 179px;
  min-height: 179px;
}

.chat-user-info img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 12px;
}

.chat-detail .chat-user-info img {
  width: 80px;
  height: 80px;
}

.user-img .dummy-img {
  width: 50%;
  object-fit: contain;
}

.account-detail {
  padding: 14px;
}

body[dir="rtl"] .chat-user-info img {
  margin-right: 0;
  margin-left: 10px;
}

.chat-user-info h4 {
  color: #10214f;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.chat-user-info h5 {
  color: #586484;
  font-size: 16px;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.boldName .chat-user-info h4 {
  font-weight: bold;
}

.approval-lists .chat-user-info h4 {
  color: black;
}

.navbar-toggler-icon {
  width: 26px;
  height: 40px;
}

.chat-user-info span {
  font-size: 13px;
  color: #767676;
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.name-label {
  width: 200px;
}

.switch-button {
  margin-left: 40px;
  display: flex;
}

.telegram-login-btn {
  margin-left: 10px;
}

.disabled-cursor {
  cursor: not-allowed;
}

.disabled-event {
  pointer-events: none;
}

.form-check-input:disabled {
  pointer-events: all;
}

.cursor-pointer {
  cursor: pointer;
}

.night-hour-form .text-center .btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  pointer-events: all !important;
  opacity: 0.65;
}

.header-wrap .ant-avatar-lg {
  margin-left: 7px;
  cursor: pointer;
}

.child-parent-min-height {
  min-width: 250px;
  padding: 30px;
  background-color: #3ea8ff;
  border-radius: 15px;
  margin: 10px;
}

.list-box {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}

.heading h5 {
  font-size: 22px;
  font-weight: 600;
  padding-top: 20px;
  margin-bottom: 20px;
}

.heading h5 svg {
  vertical-align: text-top;
  margin-left: 4px;
}

body[dir="rtl"] .heading h5 svg {
  margin-left: 0;
  margin-right: 4px;
}

.userdrop-menu .ant-dropdown-menu-item .menu-item {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.userdrop-menu .ant-dropdown-menu-item .menu-item h6,
.userdrop-menu .ant-dropdown-menu-item .menu-item i {
  font-size: 16px;
  margin-bottom: 0;
}

.page-heading {
  color: #fff;
  text-transform: capitalize;
  margin-right: auto;
  display: flex;
  align-items: center;
}

.page-heading i {
  font-size: 26px;
  margin-right: 10px;
  cursor: pointer;
  vertical-align: middle;
}

.page-title {
  font-size: 20px;
  margin-left: 50px;
  margin-bottom: 0;
  color: #fff;
}

body[dir="rtl"] .page-title {
  margin-left: inherit;
  margin-right: 50px;
}

body[dir="rtl"] .page-heading {
  margin-left: auto;
  margin-right: 0;
}

body[dir="rtl"] .page-heading i {
  margin-left: 10px;
  margin-right: 0;
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.modal-dialog {
  max-width: 550px;
  width: 550px !important;
  margin: 1.75rem auto;
}

.modal-dialog .primary {
  color: #fff;
  border-color: #1890ff;
  background: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px #0000000b;
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
}

.modal-dialog .normal {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px #00000004;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: #000000d9;
  border-color: #d9d9d9;
  background: #fff;
}

.ant-modal-footer button + button {
  margin-left: 10px;
}

body[dir="rtl"] .ant-modal-footer button + button {
  margin-right: 10px;
}

.toggle-white .navbar-toggler-icon {
  background-image: url("../images/toggle-white.svg");
}

.toggle-white {
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
}

.who-box-wrap {
  min-height: -moz-calc(100vh - 100px);
  min-height: -webkit-calc(100vh - 100px);
  min-height: calc(100vh - 100px);
  display: flex;
  align-items: center;
}

.who-box {
  /* max-width: 800px; */
  width: 100%;
  /* margin: 0 auto; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, .12); */
  padding: 20px;
}

.who-box .who-box-content .who-title {
  font-size: 26px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 30px;
}

.who-box .who-box-content .sub-title {
}

.who-box .who-box-content .sub-title {
}

.icon-box {
  max-width: 500px;
}

.icon-box ul {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.icon-box li {
  list-style: none;
  width: 25%;
  padding: 10px;
  position: relative;
}

.hover-box {
  position: absolute;
  top: 80px;
  left: 30px;
  min-width: 10rem;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  display: none;
  z-index: 9;
}

.hover-box span {
  display: block;
  white-space: nowrap;
}

.icon-box li:hover .hover-box {
  display: block;
}

.icon-box li.not-allow span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  pointer-events: none;
  width: 1px;
  bottom: 0;
  background-color: #000;
  margin: 0 auto;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.icon-box li > span {
  width: 100px;
  height: 100px;
  border: 1px solid #999;
  line-height: 100px;
  text-align: center;
  display: block;
  position: relative;
  border-radius: 100%;
}

.icon-box li span svg {
  font-size: 60px;
  width: 1em;
  height: 1em;
  margin-top: 18px;
}

.ant-tooltip {
  max-width: none;
}

.ant-tooltip-inner span {
  display: block;
  white-space: nowrap;
}

.kids-input {
  display: flex;
}

.kids-input .btn[disabled] {
  background-color: #ddd;
  border-color: #ddd;
  pointer-events: none !important;
  color: #000;
}

.notification-list {
  padding: 0;
  margin: 0 auto;
}

.notification-list .infinite-scroll-component__outerdiv {
  max-width: 650px;
  margin: 0 auto;
  width: 100%;
}

.notification-list li {
  list-style: none;
}

.notification-list li:not(.unread-notifications) {
  padding: 20px;
  margin: 20px 10px;
  line-height: 34px;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}

.notification-list .btn {
  min-width: 100px;
  border-radius: 10px;
  padding: 5px 20px;
  font-size: 18px;
  margin-right: 5px;
  margin-left: 0 !important;
  text-transform: capitalize;
  text-align: center;
}

.notification-list .btn-light {
  background-color: #c7c3bd;
  color: #fff;
}

.notification-list .btn-primary {
  background-color: #006fff;
  border-color: #006fff;
}

.input-loader {
  position: absolute;
  right: -30px;
}

.add-chat-btn {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 0;
  width: auto;
}

body[dir="rtl"] .add-chat-btn {
  left: 0px;
  right: auto !important;
}

.plus-btn {
  border: 0;
  height: 100px;
  border-radius: 100px;
  background-color: transparent;
  width: 100px;
}

.add-chat-btn span {
  font-size: 50px;
  color: #54a4d7;
}

.self-lock {
  max-width: 500px;
}

.self-lock label {
  display: block;
  margin-bottom: 10px;
}

.self-lock ul {
  padding: 0;
  margin: 0 -10px;
  display: flex;
  flex-wrap: wrap;
}

.self-lock li {
  list-style: none;
  padding: 0 10px;
  width: 33.33%;
  margin-bottom: 10px;
}

.self-lock li.selected span {
  background-color: #ffa611;
}

.self-lock li span {
  background-color: #efeaea;
  color: #000;
  padding: 10px;
  display: block;
  border-radius: 10px;
  text-align: center;
}

.self-lock input {
  margin: 0 auto;
  display: table;
  border: 0;
  border-radius: 5px;
  padding: 10px 20px;
}

.add-box {
  width: 178px;
  height: 100%;
  min-height: 254px;
  text-align: center;
  display: block;
  border-radius: 10px;
  font-size: 46px;
  color: #3ea8ff;
  background-color: #f4f8fd;
  line-height: normal;
  cursor: pointer;
  display: flex;
}

.add-box svg {
  line-height: normal;
  height: 87px;
  width: 40px;
  margin: auto;
}

.add-box .plus-btn {
  height: 85px;
}

.add-box span {
  display: block;
}

.message {
  white-space: pre-wrap;
}

.unread-notifications {
  text-align: center;
  position: relative;
}

.unread-notifications span {
  background-color: #fff;
  padding: 0 15px;
  position: relative;
}

.unread-notifications:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 2px;
  background-color: #bbb;
}

.self-lock-wrap {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.self-lock-wrap h2 {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 2px solid #ddd;
}

.timer > div {
  margin: 0 auto;
  display: table;
}

.time-wrapper .time {
  font-size: 22px;
  display: flex;
  direction: ltr;
}

.time-col {
  width: 25%;
  color: #000;
  position: relative;
}

.time-col span {
  display: block;
  font-size: 16px;
  color: #8b8b8b;
}

.time-wrapper {
  width: 100%;
  padding: 0 15px;
}

.time-col:before {
  content: ":";
  position: absolute;
  right: -2px;
  top: -2px;
}

.time-col:last-child:before {
  content: normal;
}

.self-lock-wrap .btn-primary,
.self-lock .btn-primary {
  background-color: #36a9ff;
  border-color: #36a9ff;
}

.self-lock-wrap .btn-red {
  background-color: #ea2f20;
  border-color: #ea2f20;
  color: #fff;
  text-transform: capitalize;
}

.gps-lock {
  height: 400px;
  position: relative;
  width: 100%;
}

.gps-lock > div > div > div {
  height: 100% !important;
  width: 100% !important;
}

.self-lock .btn-primary {
  min-width: 145px;
}

.waiting-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: -moz-calc(100vh - 100px);
  min-height: -webkit-calc(100vh - 100px);
  min-height: calc(100vh - 100px);
  justify-content: center;
}

.self-lock-wrapper {
  max-width: 500px;
  margin: 0 auto;
}

.remove-btn .ant-spin {
  color: #fff;
  margin-left: 4px;
}

.language-selection {
  display: flex;
  min-height: -moz-calc(100vh - 120px);
  min-height: -webkit-calc(100vh - 120px);
  min-height: calc(100vh - 120px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.language-selection button {
  border: 0;
  padding-left: 0;
  font-size: 30px !important;
}

.language-selection .ReactFlagsSelect-module_selectOptionWithlabel__2GpmM {
  font-size: 30px;
}

.language-selection .ReactFlagsSelect-module_selectOptions__3LNBJ {
  max-height: 300px;
  min-width: 20rem;
}

.language-selection button > span > span:first-child,
.language-selection .ReactFlagsSelect-module_selectFlag__2q5gC {
  border-radius: 100%;
  width: 80px;
  height: 80px;
  overflow: hidden;
}

.language-selection button svg,
.language-selection .ReactFlagsSelect-module_selectFlag__2q5gC svg {
  width: 100%;
  height: 100%;
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.language-selection .ReactFlagsSelect-module_selectBtn__19wW7:after {
  border-top: 15px solid #4d4d4d;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 0;
}

.language-selection
  .ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="true"]:after {
  border-top: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #4d4d4d;
}

[dir="rtl"] .ms-3 {
  margin-left: 0 !important;
  margin-right: 1rem !important;
}

[dir="rtl"] .ReactFlagsSelect-module_label__27pw9,
[dir="rtl"] .ReactFlagsSelect-module_secondaryLabel__37t1D {
  padding-left: 0;
  padding-right: 10px;
}

.payment-box,
.login-box {
  display: inline-block;
  margin: auto;
  max-width: 676px;
}

.payment-box p,
.login-box p {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.payment-box button,
.login-box button {
  min-width: 148px;
  min-height: 54px;
  border-radius: 10px;
  background-color: #00b0f0;
  font-size: 20px;
  padding: 12px 24px;
  border: none;
  color: #fff;
}

.submit-button {
  border-radius: 10px;
  background-color: #00b0f0;
  font-size: 20px;
  padding: 10px 0px;
  border: none;
  color: #fff;
  width: 226px;
  margin-left: 170px;
}

.hide-payment-form {
  display: none;
}

@media screen and (min-width: 1400px) {
  .chat-detail-wrap {
    flex: 0 0 auto;
    width: 24.666667%;
  }
}

@media screen and (max-width: 1800px) {
  .chat-detail-wrap {
    flex: 0 0 auto;
    width: 25%;
  }
}

@media screen and (max-width: 1400px) {
  .chat-detail-wrap {
    flex: 0 0 auto;
    width: 49%;
  }
}

@media screen and (max-width: 992px) {
  header .navbar .navbar-nav .nav-link {
    padding: 20px 8px;
    font-size: 14px;
  }

  .custom-select select {
    font-size: 14px;
  }

  .custom-select:before {
    top: 3px;
  }

  .chat-detail-wrap {
    flex: 0 0 auto;
    width: 100%;
  }

  .night-hours-box {
    flex: 0 0 auto;
    width: 25%;
  }
}

@media screen and (min-width: 767px) {
  .navbar-collapse {
    height: auto !important;
  }
}

@media screen and (max-width: 769px) {
  .navbar-collapse {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 0 8px 9px rgba(0, 0, 0, 0.08);
  }

  header .navbar .navbar-nav .nav-link {
    padding: 10px;
  }

  .navbar-collapse ul {
    max-width: 540px;
    margin: 0 auto;
  }

  .sidebar-right-side {
    width: 100%;
    padding: 70px 15px 15px;
  }

  .navbar-toggler {
    display: block;
  }

  .icon-box li {
    width: 20%;
  }

  .icon-box li > span {
    width: 75px;
    height: 75px;
    line-height: 75px;
  }

  .icon-box li span svg {
    font-size: 40px;
    margin-top: 17px;
  }

  .icon-box li:nth-child(4) .hover-box,
  .icon-box li:nth-child(5) .hover-box {
    left: auto;
    right: 30px;
  }
}

@media screen and (max-width: 575px) {
  .night-hours-box {
    width: 33.33%;
  }

  /* .icon-box li > span {
    width: 50px;
    height: 50px;
    line-height: 46px;
    margin: 0 auto;
  } */
  /* .icon-box li span svg {
    font-size: 20px;
  } */
  .submit-button {
    margin-left: 130px;
  }

  .hover-box {
    top: 50px;
  }

  .language-selection button > span > span:first-child,
  .language-selection .ReactFlagsSelect-module_selectFlag__2q5gC {
    width: 50px;
    height: 50px;
  }

  .language-selection .ReactFlagsSelect-module_selectOptions__3LNBJ {
    min-width: 15rem;
  }

  .language-selection .ReactFlagsSelect-module_selectBtn__19wW7:after {
    border-top: 10px solid #4d4d4d;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 0;
  }

  .language-selection
    .ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="true"]:after {
    border-top: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #4d4d4d;
  }

  .list-box {
    flex-wrap: inherit;
    overflow-x: auto;
  }

  .header-wrap .navbar-toggler.bg-transparent {
    display: none;
  }

  .side-bar-menu {
    width: 100%;
    float: right;
    padding: 70px 10px 70px;
    min-height: 100vh;
    margin-left: 0;
  }

  body[dir="rtl"] .side-bar-menu {
    float: left;
    margin-right: 0;
  }

  .sidebar-view {
    width: 100%;
    height: 70px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #369fff;
    border-top: 1px solid #cddef1;
    border-right: 0;
    top: auto;
  }

  .sidebar-view ul {
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 16px;
    align-items: center;
  }

  .sidebar-view ul li {
    margin-top: 0 !important;
  }

  .sidebar-view li .nav-link {
    margin: 0;
  }

  .sidebar-view li .nav-link {
    color: rgb(255 255 255 / 50%);
  }

  .sidebar-view li .nav-link.active svg,
  .sidebar-view li .nav-link:hover {
    color: #fff;
  }

  .sidebar-view li .nav-link.active {
    background-color: #68b6ff;
    color: #fff;
  }

  .add-chat-btn {
    position: fixed;
    right: 0px;
    bottom: 60px;
    padding: 0;
    width: auto;
  }

  .page-title {
    margin: 0;
  }

  body[dir="rtl"] .page-title {
    margin: 0;
  }
}

/* New CSS */

:root {
  --primary-blue: #369fff;
  --primary-blue-hover: #2086e3;
  --white: #fff;
  --black: #000;
  --primary-text: #10214f;
  --secondary-text: #586484;
  --secondary-bg: #f4f8fd;
  --title-orange: #f2a372;
  --title-green: #88ca68;
  --title-violet: #8f75f8;
  --border-gray: rgba(215, 222, 240, 0.5);
  --hover-gray: #f6f6f6;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul,
ol {
  margin: 0;
  padding-left: 0;
}

p {
  margin: 0;
}

html,
body {
  height: 100%;
}

body {
  font-family: Helvetica, sans-serif;
  font-size: 16px;
}

button {
  margin: 0;
  font-family: inherit;
  line-height: normal;
}

a,
a:active {
  text-decoration: none;
}

img,
svg {
  vertical-align: unset;
}

ul {
  list-style: none;
}

/* Globals */
.container {
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 20px;
}

.arrow-down {
  width: 18px;
  transition: 0.3s ease;
}

.arrow-down.active {
  transform: rotate(180deg);
}

.icon {
  width: 18px;
}

.lang-icon {
  width: 32px;
}

.label--soon {
  font-size: 13px;
  background-color: var(--primary-blue);
  color: var(--white);
  border-radius: 12px;
  padding-inline: 4px;
  margin-left: 9px;
  text-transform: uppercase;
}

body[dir="rtl"] .label--soon {
  margin-right: 9px;
  margin-left: 0px;
}

.main {
  padding-top: 180px;
}

.dark {
  color: var(--primary-text);
}

.blue {
  color: var(--primary-blue);
}

/* Header styles */

.header {
  background-color: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  max-width: 100%;
  padding: 10px 0;
  z-index: 999;
  transition: all 0.3s ease;
}

.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.header .brand__logo {
  max-width: 120px;
  object-fit: cover;
  display: inline-block;
}

/* Navbar styles */

.nav .nav__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav__item:not(:last-of-type) {
  margin-right: 40px;
}

.nav__link {
  color: var(--primary-text);
  font-weight: bold;
  cursor: pointer;
}

.nav__link--download {
  display: flex;
  align-items: flex-start;
  position: relative;
}

/* Header actions */

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.actions button,
.actions button:hover,
.actions button:focus {
  border-radius: 30px;
  background-color: #54a9eb;
  border-color: #54a9eb;
}

.btn--login {
  color: var(--white);
  margin-left: auto;
  max-width: 250px;
  /* padding-inline: 14px;
  padding-block: 10px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  gap: 20px;
  transition: all 0.3s; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.language-list button {
  border: none;
  background-color: var(--white);
}

.language-list ul {
  border: none;
  box-shadow: 12px 12px 70px rgba(17, 51, 102, 0.1);
}

.language-list li {
  display: flex;
  justify-content: center;
}

/* .btn--login:hover {
  background-color: var(--primary-blue-hover);
} */

.hamburger {
  display: none;
}

/* Downloads window style */

.downloads {
  background-color: var(--white);
  padding: 18px 23px;
  border-radius: 10px;
  box-shadow: 12px 12px 70px rgba(17, 51, 102, 0.1);
  position: absolute;
  width: 220px;
  top: 25px;
}

.download__submenu_header {
  left: 230px !important;
  width: 330px !important;
}

.download__submenu_header .donwloads__item,
.download__submenu_hero .donwloads__item {
  column-gap: 10px;
}

body.dir-ltr .download__submenu_hero {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-40%, 30%);
  width: 330px !important;
  z-index: 100;
  /* left: 0 !important;
    top: -35px */
}
body.dir-rtl .download__submenu_hero {
  transform: translate(-80%, 15%);
  top: 50%;
  left: 50%;
  width: 330px !important;
  z-index: 100;
}


body.dir-rtl .downloads.download__submenu_header{
  transform: translate(-130%, 80%);
  min-height: 200px;
}


@media (width < 768px) {
  body.dir-ltr .download__submenu_hero {
    transform: translate(-50%, -50%);

    top: 35px;
    left: 50%;
  }

  body.dir-rtl .download__submenu_hero {
    transform: translate(-60%, 10%);
    top: 35px;
    left: 50%;
  }

  body.dir-ltr .download__submenu_hero_windows {
    transform: translate(-80%, -50%);
    top: 50%;
    left: 50%;
  }

  body.dir-rtl .download__submenu_hero_windows {
    transform: translate(-40%, 40%);
    top: 50%;
    left: 50%;
  }

  body.dir-rtl .downloads.download__submenu_header{
    transform: translate(0%, 8%);
  }
  
}

.downloads__list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.donwloads__item {
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.downloads__item-icon {
  width: 18px;
}

.downloads__item-link a {
  font-size: 15px;
  color: var(--black);
  display: inline-block;
}

.downloads__item-link--labled {
  display: flex;
  align-items: center;
}

/* Hero section styles */

.hero__inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 200px;
}

.hero__content {
  width: 100%;
}

.hero__logo {
  width: 89px;
  margin-bottom: 48px;
}

.hero__logo img {
  max-width: 100%;
  object-fit: cover;
}

.hero__info {
  margin-bottom: 48px;
}

.hero__title {
  text-transform: capitalize;
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 16px;
  color: var(--primary-text);
  font-weight: 700;
}

.hero__text {
  font-size: 24px;
  line-height: 26px;
  color: var(--secondary-text);
}

.hero__apps-list {
  display: flex;
  align-items: stretch;
  gap: 48px;
}

#popover-basic .popover-arrow {
  display: none !important;
}

.hero__apps-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.hero__apps-icon {
  width: 32px;
  display: inline-block;
  padding-bottom: 10px;
}

.hero__apps-icon svg g {
  fill: var(--primary-blue);
}

.hero__apps-title {
  font-size: 16px;
  color: var(--primary-text);
}

.hero__apps-title--soon {
  color: var(--secondary-text);
  position: relative;
}

.hero__apps-item--soon {
  position: relative;
}

.hero__apps-item--soon .hero__apps-icon {
  opacity: 0.5;
}

.hero__apps-item--soon .label--soon {
  position: absolute;
  background-color: var(--title-green);
  top: -12px;
  right: -20px;
  font-size: 12px;
}

.hero__image {
  width: 100%;
}

.hero__image img {
  max-width: 100%;
}

/* About styles */

.about {
  padding-top: 200px;
}

.about__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 180px;
}

.about__video {
  max-width: 578px;
  width: 100%;
  position: relative;
  height: 324px;
}

.about__video-thumbnail {
  max-width: 100%;
  object-fit: cover;
}

.about__video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.about__content {
  width: 100%;
  text-align: right;
}

.about__title {
  color: var(--primary-text);
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 20px;
  font-weight: bold;
}

.about__text {
  color: var(--secondary-text);
  font-size: 24px;
  line-height: 32px;
}

/* How it works styles */

.working {
  margin-top: 100px;
  padding-block: 60px;
  background-color: var(--secondary-bg);
}

.working__inner {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.working__title {
  font-size: 36px;
  text-align: center;
  color: var(--primary-text);
  line-height: 48px;
  margin-bottom: 48px;
  font-weight: 700;
}

.working__steps {
  display: flex;
  justify-content: space-between;
}

.working__item {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.working__item-step {
  color: var(--secondary-text);
  font-size: 24px;
  line-height: 32px;
  max-width: 210px;
  margin: 0 auto;
  padding-bottom: 36px;
}

.working__item-box {
  width: 240px;
  height: 180px;
}

.working__item-box img {
  max-width: 100%;
}

/* Features styles */

.features {
  margin-top: 110px;
}

.features__inner {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.feature {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 120px;
}

.feature:nth-child(even) {
  flex-direction: row-reverse;
}

.feature__content {
  flex: 1;
  max-width: 525px;
}

.feature__title {
  font-size: 32px;
  line-height: 38px;
  color: var(--primary-text);
  margin-bottom: 24px;
  font-weight: 700;
}

.premium .feature__title {
  font-size: 36px;
  line-height: 48px;
}

.premium .feature:nth-child(1) .feature__title {
  color: var(--primary-blue);
}

.premium .feature:nth-child(2) .feature__title {
  color: var(--title-green);
}

.premium .feature:nth-child(3) .feature__title {
  color: var(--title-orange);
}

.premium .feature:nth-child(4) .feature__title {
  color: var(--primary-blue);
}

.feature:nth-child(1) .feature__title {
  color: var(--title-orange);
}

.feature:nth-child(2) .feature__title {
  color: var(--primary-blue);
}

.feature:nth-child(3) .feature__title {
  color: var(--title-green);
}

.feature:nth-child(4) .feature__title {
  color: var(--title-violet);
}

.feature:nth-child(5) .feature__title {
  color: var(--primary-blue);
}

.feature__text {
  font-size: 24px;
  line-height: 40px;
  color: var(--secondary-text);
}

.feature__image {
  flex: 1;
  max-width: 460px;
}

.feature__image img {
  max-width: 100%;
}

/* Cooperation styles */
.cooperation {
  margin-top: 70px;
  padding-block: 60px;
  background-color: var(--secondary-bg);
}

.cooperation__inner {
  display: flex;
  flex-direction: column;
}

.cooperation__title {
  text-align: center;
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 40px;
  color: var(--primary-text);
  font-weight: 700;
}

.cooperation__items {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 40px;
}

.cooperation__item {
  width: 240px;
}

.cooperation__item img {
  max-width: 100%;
  object-fit: cover;
}

/* Support section styles */
.support {
  margin-top: 120px;
}

.support__inner {
  display: flex;
  justify-content: space-between;
}

.contacts {
  width: 40%;
}

.contacts__title {
  font-size: 32px;
  line-height: 32px;
  color: var(--primary-text);
  margin-bottom: 32px;
  font-weight: 700;
}

.contacts__list {
  display: flex;
  flex-direction: column;
}

.contacts__item {
  display: flex;
  align-items: center;
  gap: 16px;
}

.contacts__item:not(:last-of-type) {
  margin-bottom: 16px;
}

.contacts__item-image {
  display: inline-block;
  width: 70px;
  border-radius: 50%;
  padding: 5px;
}

.contacts__item-link {
  font-size: 24px;
  line-height: 32px;
  color: var(--secondary-text);
}

.faq {
  width: 50%;
  margin-left: auto;
}

.faq__title {
  font-size: 32px;
  line-height: 32px;
  color: var(--primary-text);
  margin-bottom: 32px;
  font-weight: 700;
}

.accordion {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.accordion-item {
  padding: 20px 0;
  border-left: none;
  border-right: none;
  border-top: 1px solid var(--border-gray);
  display: flex;
  flex-direction: column;
  max-height: 100%;
  transition: all 0.3s ease;
}

.accordion-item:hover {
  background-color: var(--hover-gray);
}

.accordion-item:last-of-type {
  border-bottom: 1px solid var(--border-gray);
}

.accordion-question {
  color: var(--primary-text);
}

.accordion-button {
  color: var(--primary-text);
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  border: none;
  border-color: inherit;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
}

.accordion-button:not(.collapsed) {
  color: var(--primary-text);
  background-color: transparent;
}

.accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.125);
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../../assets/icons/FAQ_close.svg") !important;
}

.accordion-button::after {
  background-image: url("../../assets//icons/FAQ_open.svg") !important;
}

.accordion-answer {
  font-size: 16px;
  line-height: 24px;
  color: var(--secondary-text);
}

.faq__link-box {
  display: block;
}

.faq__link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #369fff;
  font-size: 20px;
  font-weight: 600;
}

/* Apps downloads buttons */

.apps {
  margin-top: 100px;
  background-color: var(--primary-blue);
  padding-block: 50px;
}

.apps__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.apps__title {
  color: var(--white);
  font-size: 36px;
  margin-bottom: 44px;
  line-height: 48px;
}

.apps__buttons {
  display: flex;
  max-width: 600px;
  margin: 0 auto;
  gap: 32px;
}

.btn-app {
  background: transparent;
  border: 2.5px solid var(--white);
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
  border-radius: 20px;
  width: 180px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.apps__icon {
  width: 16px;
}

.mobile__menu {
  background-color: var(--white);
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(-200%);
  transition: all 0.3s linear;
  z-index: 10;
  padding-block: 25px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  padding: 20px 35px;
  margin-top: 16px;
}

.mobile__menu.open {
  transform: translateY(0);
}

.mobile__menu-actions {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
}

.mobile__menu-actions > button,
.mobile__menu-actions > button:hover,
.mobile__menu-actions > button:focus {
  border-radius: 30px;
  background-color: #54a9eb;
  border-color: #54a9eb;
}

/* .mobile__menu-actions .btn--login {
  border: 1px solid var(--white);
} */

.mobile__menu .nav {
  display: block;
  width: 100%;
  margin-bottom: 23px;
}

.mobile__menu .nav__list {
  flex-direction: column;
}

.mobile__menu .nav__item {
  margin-right: 0;
  padding-block: 15px;
  border-top: 1px solid var(--border-gray);
  width: 100%;
  text-align: start;
  justify-content: flex-end;
}

.mobile__menu .nav__item:last-of-type {
  border-bottom: 1px solid var(--border-gray);
}

.mobile__menu .nav__link {
  text-align: right;
  display: inline;
}

.mobile__menu .downloads {
  position: static;
  box-shadow: none;
  padding: 0;
  margin-left: auto;
  padding-top: 17px;
}

.mobile__menu .downloads__list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.mobile__menu .donwloads__item {
  flex-direction: row-reverse;
}

.mobile__menu .downloads__item-link {
  font-weight: normal;
}

.mobile__menu .downloads__item-link--labled {
  flex-direction: row-reverse;
  gap: 10px;
}

/* FAQ page styles */

.faq__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--primary-blue);
  padding-top: 162px;
  padding-bottom: 72px;
  margin-bottom: 48px;
}

.faq__header-title {
  color: var(--white);
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  margin-bottom: 24px;
}

.faq__search {
  width: 500px;
  margin: 0 auto;
}

.search-label {
  color: var(--secondary-text);
  display: inline-block;
  margin-bottom: 22px;
}

.search-label_result {
  font-weight: bold;
  text-transform: capitalize;
}

.form__group {
  display: flex;
  align-items: center;
  width: 100%;
}

.input {
  width: 100%;
  border: none;
  padding-block: 15px;
  padding-inline: 60px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 6px;
}

.input::placeholder {
  color: var(--white);
}

.input:focus {
  outline: none;
}

.input-icon {
  position: absolute;
  padding-left: 20px;
  display: block;
}

.faq__view-list {
  margin-bottom: 48px;
}

.faq__view-list__title {
  color: var(--primary-blue);
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 22px;
  font-weight: 600;
}

/* Support page styles */

.support__view {
  padding-top: 160px;
}

.support__view-inner {
  display: flex;
  justify-content: space-between;
}

.support__item {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.support__item-title {
  color: var(--primary-text);
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 40px;
  font-weight: 700;
}

.support__item-text {
  color: var(--secondary-text);
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 50px;
}

.support__item-link {
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
}

.support__item-link img {
  margin-right: 16px;
  width: 70px;
}

.legal {
  margin-top: auto;
}

.legal-title {
  font-size: 28px;
  color: var(--secondary-text);
  margin-bottom: 24px;
}

.legal__list {
  display: flex;
}

.legal__item {
  margin-right: 24px;
}

.legal__link {
  color: var(--secondary-text);
  text-decoration: underline;
  font-size: 20px;
}

/* Premium page styles */

.premium__hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.preamium__hero-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.premium__hero-content {
  flex: 1;
  margin-right: 190px;
  max-width: 410px;
}

.premium__hero-title {
  font-size: 40px;
  line-height: 48px;
  color: var(--primary-text);
  font-weight: 700;
  margin-bottom: 24px;
}

.premium__hero-text {
  font-size: 28px;
  line-height: 32px;
  color: var(--secondary-text);
}

.premium__hero-image {
  flex: 1;
}

.premium__hero-image img {
  width: 100%;
  object-fit: cover;
}

/* Premium pricing cards styles */

.pricing {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pricing-title {
  color: var(--primary-text);
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 48px;
  font-weight: 700;
  text-align: center;
}

.pricing__cards {
  display: flex;
  justify-content: center;
  width: 100%;
}

.pricing__cards-item {
  background: var(--white);
  box-shadow: 2px 6px 40px rgba(0, 9, 43, 0.15);
  border-radius: 20px;
  max-width: 500px;
  width: 100%;
  padding: 40px;
}

.pricing__cards-item__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 250px;
  margin: 0 auto;
  text-align: center;
}

.pricing__cards-item__inner button,
.pricing__cards-item__inner button:hover,
.pricing__cards-item__inner button:focus {
  background-color: #54a9eb;
  border-color: #54a9eb;
  border-radius: 30px;
}

.pricing-price {
  color: var(--primary-blue);
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 16px;
}

.pricing-text {
  color: var(--secondary-text);
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 24px;
}

/* Premium buy section */

.buy {
  background-color: var(--secondary-bg);
  margin-top: 160px;
  padding-bottom: 140px;
}

.buy .pricing-title {
  color: var(--primary-blue);
  font-size: 36px;
  line-height: 48px;
}

.login-wrap {
  padding-top: 72px;
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-wrap svg {
  margin-bottom: 15px;
}

.login-wrap h4 {
  padding-bottom: 20px;
  color: #2b3655;
  font-weight: 600;
}

.login-wrap h3 {
  margin-top: 20px;
  color: #717581;
  margin-bottom: 30px;
}
.login-wrap .link-preview-btn {
  padding: 50px;
  background: yellow;
  color: black;
  font-size: 22px;
  border-radius: 12px;
  border: 1px solid #007aff;
}

.deceleration-main {
  direction: rtl;
  padding: 100px 30px;
}
.deceleration-section {
  background: #fff;
  padding: 5px;
}
.deceleration-section p {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 14px;
}
.deceleration-section p .deceleration-link {
  color: red;
}
/* Media queries */

@media screen and (max-width: 1024px) {
  .container {
    padding: 20px 30px;
  }

  .actions {
    margin-left: auto;
  }

  .btn--login {
    display: flex;
  }

  .mobile__menu .btn--login {
    display: none;
  }

  .mobile__menu .language-switcher {
    display: block;
  }

  .language-switcher {
    display: none;
  }

  .header__inner .nav {
    display: none;
  }

  /* Hambuger */
  .hamburger {
    background: transparent;
    border: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: relative;
    z-index: 10;
    transition: all 0.2s ease;
    padding-right: 15px;
    display: block;
    align-self: center;
  }

  .hamburger__line {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 2px;
    background-color: var(--black);
    transform: rotate(0);
    transition: all 0.3s ease;
  }

  .hamburger__line-middle {
    transform: translateY(8px);
  }

  .hamburger__line-bottom {
    transform: translateY(16px);
  }

  .hamburger.open .hamburger__line-top {
    transform: rotate(45deg) translateY(7px) translateX(7px);
  }

  .hamburger.open .hamburger__line-middle {
    display: none;
  }

  .hamburger.open .hamburger__line-bottom {
    transform: rotate(-45deg) translateY(7px) translateX(-7px);
  }

  .hero__inner {
    gap: 50px;
  }

  .about {
    padding-top: 100px;
  }

  .about__inner {
    flex-direction: column-reverse;
    gap: 48px;
  }

  .about__video {
    margin: 0 auto;
    max-width: 600px;
  }

  .about__content {
    text-align: center;
    max-width: 500px;
  }

  .features__inner {
    align-items: center;
    gap: 125px;
  }

  .feature {
    flex-direction: column;
    gap: 40px;
    max-width: 500px;
  }

  .feature__content {
    max-width: 100%;
    text-align: center;
  }

  .feature:nth-child(even) {
    flex-direction: column;
  }

  .feature__image {
    width: 100%;
  }

  .support__inner {
    flex-direction: column-reverse;
    gap: 162px;
  }

  .faq {
    width: 100%;
  }

  .faq__title {
    text-align: center;
  }

  .contacts {
    width: 100%;
  }

  .contacts__title {
    text-align: center;
  }

  .contacts__list {
    max-width: 350px;
    margin: 0 auto;
  }

  .faq__search {
    max-width: 100%;
    padding: 0 20px;
  }

  .support__view-inner {
    flex-direction: column;
  }

  .support__item {
    text-align: center;
    margin-bottom: 48px;
  }

  .support__item-title {
    margin-bottom: 24px;
  }

  .support__item-text {
    margin-bottom: 24px;
  }

  .support__item-link {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .legal-title {
    font-size: 24px;
    color: var(--secondary-text);
    margin-bottom: 20px;
    text-align: center;
  }

  .legal__list {
    justify-content: center;
  }

  .legal__link {
    font-size: 18px;
  }

  .preamium__hero-inner {
    flex-direction: column;
  }

  .premium__hero-content {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 24px;
    text-align: center;
  }

  .premium__hero-title {
    font-size: 32px;
    line-height: 38px;
  }

  .premium__hero-text {
    font-size: 24px;
    line-height: 32px;
  }

  .pricing .btn--login {
    display: flex;
  }

  .buy {
    margin-top: 80px;
    padding-bottom: 70px;
  }
}

@media screen and (max-width: 728px) {
  .container {
    padding: 0 30px;
  }

  .header {
    padding-block: 20px;
    height: 72px;
  }

  .btn--login {
    display: none;
  }

  .mobile__menu .btn--login {
    display: flex;
  }

  .mobile__menu .language-switcher {
    display: block;
  }

  .main {
    padding-top: 100px;
  }

  .header__inner .nav {
    display: none;
  }

  .header .brand__logo {
    font-size: 24px;
  }

  .hero__inner {
    flex-direction: column;
    gap: 50px;
  }

  .hero__text {
    font-size: 24px;
    line-height: 32px;
  }

  .hero__title {
    font-size: 32px;
    line-height: 38px;
  }

  .hero__logo {
    margin: 0 auto;
    margin-bottom: 32px;
  }

  .hero__info {
    text-align: center;
  }

  .hero__apps-list {
    justify-content: center;
  }

  .hero__apps-title {
    font-size: 15px;
  }

  .about {
    padding-top: 80px;
  }

  .about__inner {
    flex-direction: column-reverse;
    gap: 50px;
  }

  .about__content {
    text-align: center;
  }

  .about__title {
    font-size: 32px;
    line-height: 38px;
  }

  .about__text {
    font-size: 24px;
    line-height: 32px;
  }

  .about__video {
    max-width: 334px;
    height: 186px;
  }

  .about__video-thumbnail {
    max-width: 100%;
    object-fit: cover;
    cursor: pointer;
  }

  .working {
    margin-top: 60px;
  }

  .working__title {
    font-size: 32px;
    line-height: 38px;
  }

  .working__steps {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .working__item-step {
    font-size: 24px;
    line-height: 32px;
  }

  .working__item:not(:last-of-type) {
    margin-bottom: 60px;
  }

  .working__item-box {
    max-width: 100%;
  }

  .features {
    margin-top: 80px;
  }

  .features__inner {
    gap: 100px;
  }

  .feature {
    flex-direction: column;
    gap: 40px;
  }

  .feature:nth-child(even) {
    flex-direction: column;
  }

  .feature__content {
    max-width: 100%;
    text-align: center;
  }

  .feature__title {
    font-size: 32px;
    line-height: 38px;
  }

  .feature__text {
    font-size: 24px;
    line-height: 40px;
    text-align: left;
  }

  .feature__image {
    width: 100%;
  }

  .cooperation__title {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 92px;
  }

  .cooperation__items {
    flex-direction: column;
    gap: 40px;
  }

  .cooperation__item {
    max-width: 230px;
    margin: 0 auto;
  }

  .support {
    margin-top: 80px;
  }

  .support__inner {
    flex-direction: column-reverse;
    gap: 100px;
  }

  .contacts {
    width: 100%;
    margin-bottom: 32px;
  }

  .contacts__title {
    text-align: center;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 59px;
  }

  .contacts__item-image {
    max-width: 70px;
  }

  .contacts__item-link {
    font-size: 24px;
    line-height: 32px;
  }

  .faq {
    width: 100%;
  }

  .faq__title {
    text-align: center;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 38px;
  }

  .accordion-question {
    font-size: 20px;
    background-color: transparent;
  }

  .accordion-header {
    background-color: transparent;
  }

  .accordion-answer p {
    font-size: 16px;
  }

  .accordion-question-sign img {
    width: 16px;
  }

  .faq__link {
    font-size: 18px;
  }

  .apps__title {
    font-size: 36px;
    line-height: 48px;
  }

  .apps__buttons {
    flex-direction: column;
  }

  .support__item-link img {
    width: 50px;
  }
}

/* New CSS end */

@media screen and (max-width: 480px) {
  .switch-button {
    margin-left: 10px;
  }

  .chat-detail .chat-user-info img {
    width: 50px;
    height: 50px;
  }

  .chat-user-info h4 {
    font-size: 18px;
  }

  .plus-btn {
    height: 70px;
    width: 70px;
  }
}

@media screen and (max-width: 425px) {
  /* .night-hours-box {
    width: 50%;
  } */
  .icon-box li {
    width: 33.33%;
  }

  .submit-button {
    margin-left: 70px;
  }

  /* .icon-box li > span {
    width: 40px;
    height: 40px;
    line-height: 36px;
  } */
  .icon-box li:nth-child(3) .hover-box {
    left: 0;
    right: 0;
  }
}

@media screen and (max-width: 390px) {
  .submit-button {
    margin-left: 40px;
  }
}
