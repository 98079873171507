.articles {
  height: 100dvh;
  padding-top: 40px;
  padding-bottom: 5px;

  & .sections_articles {
    height: 100%;
    width: 100%;
    padding: 20px;
  }


}

@media (width <=768px) {
   .sections_articles{
    padding: 0px !important;
   }

   /* body {
    overflow: hidden !important;
   } */
}