/** Layout Principal */
.articles_layout {
    display: flex;
    height: calc(100% - 40px);
    margin-top: 20px;


    /* Estilos del sidebar */

    & .sidebar_tabs {
        width: 200px;
        padding: 20px 0;
        border-right: 1px solid #ddd;
        padding: 0px 10px;

        & .tabs {
            display: flex;
            flex-direction: column;
            align-items: center;
            list-style: none;
            padding: 0;
            margin: 0;
            gap: 10px;

            & li {
                width: 100%;

            }

            & input[type="radio"] {
                display: none;
            }

            /* Estilos del buscador */
            & input[type="search"] {
                width: 100%;
                max-width: 300px;
                padding: 8px;
                border: 1px solid #ddd;
                border-radius: 4px;
                outline-color: #54a9eb;
            }

            & label {
                display: block;
                padding: 10px 20px;
                cursor: pointer;
                transition: background-color 0.3s;
                width: 100%;
                border-radius: 5px;

            }


            & input[type="radio"]:checked+label {
                background-color: #54a9eb;
                color: white;

            }
        }


    }

    /* Estilos del contenido */

    & .tab_contents {
        flex: 1;
        padding: 0 20px;
        padding: 20px;


        & .tab_content {
            display: none;
            overflow-y: scroll;
            scrollbar-width: thin;
            scroll-behavior: smooth;
            height: 100%;

        }

    }




}

@media (width <= 768px) {
    .articles_layout {
        flex-direction: column-reverse;
        & .sidebar_tabs {
            width: 100%;

            & .tabs {
                flex-direction: row;
                padding: 0;
                border-top: 1px solid #ddd;
                overflow-x: auto;
               
                & label {
                    font-size: 16px;
                    line-height: 1.2;
                    text-align: center;
                    white-space: normal;
                }
            }

          

            & input[type="search"] {
                display: none;
            }
        }

        & .tab_contents{
            display: block;
            overflow-y: auto;
            scrollbar-width: thin;
            scroll-behavior: smooth;

            & .tab_content {
                padding: 20px;
                margin: 0 auto;
            overflow-x: hidden;

            }
        }


    }

}

.container_cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 250px));

    gap: 2rem;

    & .card {
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);



        :is(.card_header, .card_body) {
            padding: 20px;
        }

        & .card_header {

            & .card_image {
                width: 100%;
                height: 150px;
                background-color: #ddd;
                border-radius: 5px 5px 0 0;
                position: relative;


            }

            & .how_long_to_read {
                position: absolute;
                top: 130px;
                right: 25px;
                background-color: rgba(0, 0, 0, 0.2);
                color: #fff;
                padding: 5px 5px;

            }
        }






    }
}

:is(.loading, .error-container) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.error-container {
    flex-direction: column;
    gap: 20px;

    & button {
        padding: 10px 20px;
        border: none;
        background-color: #54a9eb;
        color: white;
        border-radius: 5px;
        cursor: pointer;
    }
}